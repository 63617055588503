import { HttpRequest, HttpResponse } from "@angular/common/http"
import { inject } from "@angular/core"
import { ToastService } from "src/app/data/services/components/toast/toast.service"
import { LogService } from "src/app/data/services/erp/log/log.service"
import { ToastSeverity } from "src/app/domain/enums/components/toast.enum"
import { LogAction, LogFunction, LogModule, LogScreen } from "src/app/domain/enums/erp/log.enum"
import { Toast } from "src/app/domain/interfaces/components/toast.interface"
import { LogAuxiliaryData } from "src/app/domain/models/erp/log/log-auxiliary-data.model"

export class LogParameterCenterManagement {
  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if(response.status !== 200 || response.body.error) return

    try {
      switch (auxiliaryData.function) {
        case LogFunction.EDIT_PRODUCT_SERVICE_PARAMETER:
          console.log('teste 1')
          this.generateLogEditProductServiceParameter(request)
        break
        case LogFunction.SAVE_RMS_PARAMETER:
          console.log('teste 2')
          this.generateLogEditRmsParameter(request)
        break
      }
    } catch (error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  private generateLogEditProductServiceParameter(request: HttpRequest<any>): void {
    this.logService.create(
      LogModule.PARAMETER,
      LogScreen.PARAMETER_CENTER,
      request.urlWithParams,
      LogAction.EDIT,
      0,
      null,
      'Os campos para cadastro de itens foi atualizado!',
      request.body
    )
  }

  private generateLogEditRmsParameter(request: HttpRequest<any>): void {
    this.logService.create(
      LogModule.PARAMETER,
      LogScreen.PARAMETER_CENTER,
      request.urlWithParams,
      LogAction.EDIT,
      0,
      null,
      'Os parâmetros de RMS foram atualizados!',
      request.body
    )
  }
}
