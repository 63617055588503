export enum IconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra-large'
}

export enum IconColor {
  LIGHT = 'light',
  DARK = 'dark',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  PRIMARY = 'primary',
  WARNING = 'warning',
}

export enum IconCursor {
  DEFAULT = 'default',
  POINTER = 'pointer'
}

export enum Icon {
  ACCOUTING = 'accouting',
  ADMINISTRATOR = 'administrator',
  ARROW_RIGHT = 'arrow-right',
  BUDGET = 'budget',
  CALENDAR = 'calendar',
  CHECK = 'check',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  COMPANY = 'company',
  COMPANY_BRANCHES = 'company_branches',
  CUSTOMER = 'customer',
  DASHBOARD = 'dashboard',
  DEFAULT = 'default',
  EVENTS = 'events',
  EXIT_EXPANSION = 'exit_expansion',
  EXPANSION = 'expansion',
  EYE = 'eye',
  FILES = 'files',
  FINANCIAL = 'financial',
  HELP = 'help',
  HOME = 'home',
  ITEM = 'item',
  LOWER_VARIATION = 'lower_variation',
  MENU = 'menu',
  MONEY = 'money',
  NOTIFICATION = 'notification',
  OCTADESK = 'octadesk',
  PARAMETERS = 'parameters',
  PUBLICATION_INSTABILITY = 'publication_instability',
  PURCHASES = 'purchases',
  REGISTER = 'register',
  REQUISITION = 'requisition',
  SALES = 'sales',
  SERVER = 'server',
  STOCK = 'stock',
  SUPPLIER = 'supplier',
  TAX = 'money',
  TIMER = 'timer',
  UPPER_VARIATION = 'upper-variation',
  WARNING = 'warning',
  WEB = 'web',
  WORKFLOW = 'workflow',
}