export enum SocketPulseAction {
  AUTH = 'auth',
  COMMENT = 'comment',
  ATTACHMENT = 'attachment',
  ACTIVITY = 'activity',
  APPROVE = 'approve',
  HISTORIC = 'historic',
  FINANCIAL = 'financial',
  ORDER = 'order',
  RMS = 'rms',
  NULL = 'not informed',
  LOADING = 'loading',
  AUTHORIZE = 'authorize',
  REFUND = 'refund',
  CANCEL = 'cancel',
  RESTORE = 'restore'
}

export enum SocketPulseMethod {
  AUTH = 'auth',
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
  ERROR = 'error'
}

export enum SocketPulseService {
  LOGIN = 'login',
  NOTIFICATION = 'notification',
  APPROVAL_CENTER = 'approval_center',
  AUTHORIZATION_CENTER = 'authorization_center',
  FINANCIAL_CENTER = 'financial_center'
}