
<app-tooltip [label]="tooltipLabel" [position]="tooltipPosition" [event]="tooltipEvent">
  <div [class]="size + (isChevronAlign() ? '' : ' flex')" [ngSwitch]="icon" [class.align-chevron]="isChevronAlign()" [style]="'cursor:'+cursor">
    <svg *ngSwitchCase="iconAvaible.HOME" [class]="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M17.842 6.29901L11.842 1.63201C10.759 0.789006 9.242 0.789006 8.158 1.63201L2.158 6.29901C1.427 6.86701 1 7.74101 1 8.66701V16C1 17.657 2.343 19 4 19H16C17.657 19 19 17.657 19 16V8.66701C19 7.74101 18.573 6.86701 17.842 6.29901Z" stroke-width="1.5"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.REGISTER" [class]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M21 13.067V18.972C21 20.092 20.099 21 18.987 21H5.013C3.901 21 3 20.092 3 18.972V6.028C3 4.908 3.901 4 5.013 4H12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M7.82788 17.172L11.5589 16.239C11.7349 16.195 11.8949 16.104 12.0239 15.976L20.4139 7.58599C21.1949 6.80499 21.1949 5.53899 20.4139 4.75799L20.2419 4.58599C19.4609 3.80499 18.1949 3.80499 17.4139 4.58599L9.02388 12.976C8.89588 13.104 8.80488 13.265 8.76088 13.441L7.82788 17.172" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M15.8298 6.16998L18.8298 9.16998" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>  
    <svg *ngSwitchCase="iconAvaible.WEB" [class]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M12 3.09998C16.667 8.02698 16.667 15.973 12 20.9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M12 20.9C7.33301 15.973 7.33301 8.02698 12 3.09998" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M12 3C16.982 3 21 7.018 21 12C21 16.982 16.982 21 12 21" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M12 21C7.018 21 3 16.982 3 12C3 7.018 7.018 3 12 3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M3.51001 9H20.49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M3.51001 15H20.49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.COMPANY" [class]="size" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M1.99663 0.996674H12.0008C12.5533 0.996674 13.0012 1.44458 13.0012 1.99709V17.0033H1.99663C1.44412 17.0033 0.996216 16.5554 0.996216 16.0029V1.99709C0.996216 1.44458 1.44412 0.996674 1.99663 0.996674Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M13.0012 4.99835H18.0033C18.5558 4.99835 19.0037 5.44625 19.0037 5.99877V16.0029C19.0037 16.5555 18.5558 17.0034 18.0033 17.0034H13.0012V4.99835Z" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>s
    </svg>
    <svg *ngSwitchCase="iconAvaible.COMPANY_BRANCHES" [class]="size" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M19.0075 7.54874V18.0071" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M21.0083 9.00333L11.6294 2.18549C11.2793 1.93198 10.806 1.93198 10.4559 2.18549L1 9.00333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M18.5073 6.74539V1.50021C18.5073 1.22395 18.2833 1 18.0071 1H15.0058C14.7296 1 14.5056 1.22395 14.5056 1.50021V4.1103" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M21.0083 18.0071H1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M3.00083 7.56073V18.0071" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.CALENDAR" [class]="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M5.49813 0.996246V3.9975" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.5018 0.996246V3.9975" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M0.996216 7.49896H19.0037" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      <rect [class]="color" x="0.996216" y="2.49686" width="18.0075" height="16.5069" rx="3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.MENU" [class]="size" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M1.50269 5.99996H14.5078" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1.50269 10.0017H14.5078" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1.5022 1.99838H14.5073" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.EXPANSION" [class]="size" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M16.7175 2.01039L11.7678 6.96013" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M16.7175 5.5459V2.01037" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M13.182 2.01038H16.7175" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M16.7176 17.9601L11.7678 13.0104" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M13.182 17.9601H16.7175" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M16.7176 14.4246V17.9601" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1.7677 17.9601L6.71745 13.0104" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1.76769 14.4246V17.9601" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M5.30322 17.9601H1.76769" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1.7678 2.01038L6.71755 6.96013" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M5.30335 2.0104L1.76782 2.0104" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1.76776 5.54593L1.76776 2.0104" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.EXIT_EXPANSION" [class]="size" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M15.0102 8.96023L19.9599 4.01048" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M15.0103 5.42486V8.96039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M18.5459 8.96021H15.0104" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M15.0105 15.0102L19.9602 19.9599" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M18.5458 15.0103H15.0103" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M15.0105 18.5459V15.0104" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M8.96026 15.0105L4.01052 19.9602" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M8.96015 18.5458L8.96015 15.0103" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M5.42456 15.0105L8.96009 15.0105" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M8.96023 8.96051L4.01048 4.01076" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M5.42486 8.96039H8.96039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M8.96021 5.4248V8.96034" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.HELP" [class]="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M17.56 5.11899C19.48 8.08899 19.479 11.913 17.561 14.882" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M13.5476 6.45245C15.5068 8.41171 15.5068 11.5883 13.5476 13.5476C11.5883 15.5068 8.41174 15.5068 6.45248 13.5476C4.49322 11.5883 4.49322 8.41171 6.45248 6.45245C8.41174 4.49319 11.5883 4.49319 13.5476 6.45245" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M12.321 5.551L14.905 2.412C15.281 1.955 15.969 1.922 16.388 2.34L17.661 3.613C18.08 4.032 18.046 4.72 17.589 5.096L14.45 7.68" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M7.67899 14.449L5.09499 17.588C4.71899 18.045 4.03099 18.078 3.61199 17.66L2.33899 16.387C1.91999 15.968 1.95399 15.28 2.41099 14.904L5.54999 12.32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M5.55102 7.67899L2.41102 5.09499C1.95402 4.71899 1.92102 4.03099 2.33902 3.61199L3.61202 2.33899C4.03102 1.91999 4.71902 1.95399 5.09502 2.41099L7.67902 5.54999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.449 12.321L17.588 14.905C18.045 15.281 18.078 15.969 17.66 16.388L16.387 17.661C15.968 18.08 15.28 18.046 14.904 17.589L12.32 14.45" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.882 17.561C11.913 19.479 8.08802 19.48 5.11902 17.56" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M2.43899 5.11798C0.520994 8.08698 0.519994 11.911 2.43999 14.881" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.881 2.43999C11.911 0.519994 8.08698 0.520994 5.11798 2.43899" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>      
    <svg *ngSwitchCase="iconAvaible.NOTIFICATION" [class]="size" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M5.70801 16.344V16.709C5.70801 17.974 6.73401 19 8.00001 19V19C9.26601 19 10.292 17.974 10.292 16.708V16.343" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M9.83299 3.751V2.833C9.83299 1.821 9.01299 1 7.99999 1V1C6.98699 1 6.16699 1.821 6.16699 2.833V3.751" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M2.563 8.18801V8.18801C2.563 5.68501 4.592 3.65701 7.094 3.65701H8.907C11.41 3.65701 13.438 5.68601 13.438 8.18801V8.18801V10.985C13.438 11.515 13.649 12.024 14.024 12.399L14.665 13.04C15.04 13.415 15.251 13.924 15.251 14.454V14.454C15.251 15.498 14.405 16.344 13.361 16.344H2.64C1.596 16.344 0.75 15.498 0.75 14.454V14.454C0.75 13.924 0.961 13.415 1.336 13.04L1.977 12.399C2.352 12.024 2.563 11.515 2.563 10.985V8.18801Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.REQUISITION" [class]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M8 13H16" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M8 17H16" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M6 3H15.172C15.702 3 16.211 3.211 16.586 3.586L19.414 6.414C19.789 6.789 20 7.298 20 7.828V19C20 20.105 19.105 21 18 21H6C4.895 21 4 20.105 4 19V5C4 3.895 4.895 3 6 3Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M20 8H16C15.448 8 15 7.552 15 7V3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M8 9H11" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.ARROW_RIGHT" [class]="size" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M1 6H15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M6 1L1 6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M6 11L1 6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>    
    <svg *ngSwitchCase="iconAvaible.CHEVRON_LEFT" [class]="size" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color"  d="M1.5 7L4.5 4L1.5 1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.CHEVRON_DOWN" [class]="size" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M1 0.5L4 3.5L7 0.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> 
    <svg *ngSwitchCase="iconAvaible.WORKFLOW" [class]="size" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle [class]="color" cx="16.75" cy="3.25" r="2.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <circle [class]="color" cx="16.75" cy="11" r="2.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <circle [class]="color" cx="3.25" cy="11" r="2.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <circle [class]="color" cx="16.75" cy="18.75" r="2.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.5 3.25H12C10.895 3.25 10 4.145 10 5.25V16.75C10 17.855 10.895 18.75 12 18.75H14.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.5 11H5.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.SALES" [class]="size" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M13.807 5.19262C13.9519 5.3375 13.9519 5.57241 13.807 5.71729C13.6621 5.86218 13.4272 5.86218 13.2823 5.71729C13.1375 5.57241 13.1375 5.3375 13.2823 5.19262C13.4272 5.04773 13.6621 5.04773 13.807 5.19262" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M11.5118 1H15.8968C17.0588 1 17.9998 1.941 17.9998 3.102V7.487C17.9998 8.045 17.7778 8.579 17.3838 8.974L8.97375 17.384C8.15275 18.205 6.82175 18.205 6.00075 17.384L1.61575 12.999C0.79475 12.178 0.79475 10.847 1.61575 10.026L10.0258 1.616C10.4198 1.222 10.9548 1 11.5118 1Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M3.80981 7.83997L11.1598 15.19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg  *ngSwitchCase="iconAvaible.PURCHASES" [class]="size" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M6.197 16.267C5.995 16.267 5.831 16.431 5.833 16.633C5.831 16.836 5.996 17 6.198 17C6.4 17 6.564 16.836 6.564 16.634C6.564 16.431 6.4 16.267 6.197 16.267" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.6091 16.2671C14.4071 16.2671 14.2431 16.4311 14.2451 16.6331C14.2451 16.8351 14.4091 16.9991 14.6111 16.9991C14.8131 16.9991 14.9771 16.8351 14.9771 16.6331C14.9761 16.4311 14.8121 16.2671 14.6091 16.2671" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M3.374 4L2.724 1H1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M4.90902 11.246L3.37402 4H16C16.651 4 17.128 4.611 16.97 5.243L15.622 10.635C15.417 11.454 14.721 12.056 13.881 12.14L7.06502 12.822C6.04902 12.923 5.12002 12.244 4.90902 11.246Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>      
    <svg *ngSwitchCase="iconAvaible.OCTADESK" [class]="size" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M17 17V2.778C17 1.796 16.204 1 15.222 1H2.778C1.796 1 1 1.796 1 2.778V13.445C1 14.427 1.796 15.223 2.778 15.223H13.667L17 17Z" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M12.884 10C10.707 12.177 7.177 12.177 5 10" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M7 5V6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M11 5V6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.FINANCIAL" [class]="size" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M1.039 6.91498H17C18.105 6.91498 19 7.80998 19 8.91498V18C19 19.1 18.1 20 17 20H3C1.895 20 1 19.105 1 18V7.29098C1 6.45898 1.515 5.71398 2.294 5.41998L12.647 1.51098C13.301 1.26398 14 1.74698 14 2.44598V6.91398"stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M13.999 13.125C13.792 13.126 13.625 13.294 13.625 13.501C13.625 13.708 13.793 13.876 14 13.875C14.207 13.875 14.375 13.707 14.375 13.5C14.375 13.293 14.207 13.125 13.999 13.125"stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>      
    <svg *ngSwitchCase="iconAvaible.UPPER_VARIATION" [class]="size" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M12.2007 1.39966L6.60036 7.00002L3.80018 4.19984L1 7.00002" stroke-width="1.3" stroke-linecap="square"/>
      <path [class]="color" d="M8.19958 1H12.3999V5.20027" stroke-width="1.3" stroke-linecap="square"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.LOWER_VARIATION" [class]="size" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M1.19917 6.60034L6.79954 0.999977L9.59972 3.80016L12.3999 0.999978" stroke-width="1.3" stroke-linecap="square"/>
      <path [class]="color" d="M5.20032 7L1.00004 7L1.00004 2.79973" stroke-width="1.3" stroke-linecap="square"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.STOCK" [class]="size" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M1.31002 4.527L3.40002 1.787C3.77802 1.291 4.36602 1 4.99002 1H13.01C13.634 1 14.222 1.291 14.6 1.787L16.69 4.527C16.956 4.875 17.1 5.302 17.1 5.74V17C17.1 18.105 16.205 19 15.1 19H2.90002C1.79502 19 0.900024 18.105 0.900024 17V5.74C0.900024 5.302 1.04402 4.876 1.31002 4.527Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M13 8C13 10.209 11.209 12 9 12C6.791 12 5 10.209 5 8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1.02002 5.06995H16.98" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.DASHBOARD" [class]="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M3 1H17C18.105 1 19 1.895 19 3V17C19 18.105 18.105 19 17 19H3C1.895 19 1 18.105 1 17V3C1 1.895 1.895 1 3 1Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M16 8.064L12 12L8 9L4 12.936" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.ACCOUTING" [class]="size" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M13.066 19H2.934C1.866 19 1 18.134 1 17.066V2.93498C1 1.86698 1.866 1.00098 2.934 1.00098H13.065C14.134 1.00098 15 1.86698 15 2.93498V17.065C15 18.134 14.134 19 13.066 19V19Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M15 6H1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M4.00195 12.949C4.02995 12.949 4.05195 12.971 4.05195 12.999C4.05195 13.027 4.02995 13.049 4.00195 13.049C3.97395 13.049 3.95195 13.027 3.95195 12.999C3.95195 12.972 3.97395 12.949 4.00195 12.949" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M8.00099 12.951C8.02899 12.951 8.05099 12.973 8.05099 13.001C8.05099 13.029 8.02899 13.051 8.00099 13.051C7.97299 13.051 7.95099 13.029 7.95099 13.001C7.94999 12.973 7.97299 12.951 8.00099 12.951" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M12.002 12.949C12.03 12.949 12.052 12.971 12.052 12.999C12.052 13.027 12.03 13.049 12.002 13.049C11.974 13.049 11.952 13.027 11.952 12.999C11.952 12.972 11.974 12.949 12.002 12.949" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M4.00195 9.94902C4.02995 9.94902 4.05195 9.97102 4.05195 9.99902C4.05195 10.027 4.02995 10.049 4.00195 10.049C3.97395 10.049 3.95195 10.027 3.95195 9.99902C3.95195 9.97202 3.97395 9.94902 4.00195 9.94902" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M8.00099 9.95098C8.02899 9.95098 8.05099 9.97298 8.05099 10.001C8.05099 10.029 8.02899 10.051 8.00099 10.051C7.97299 10.051 7.95099 10.029 7.95099 10.001C7.94999 9.97298 7.97299 9.95098 8.00099 9.95098" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M12.002 9.94902C12.03 9.94902 12.052 9.97102 12.052 9.99902C12.052 10.027 12.03 10.049 12.002 10.049C11.974 10.049 11.952 10.027 11.952 9.99902C11.952 9.97202 11.974 9.94902 12.002 9.94902" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M4.00195 15.949C4.02995 15.949 4.05195 15.971 4.05195 15.999C4.05195 16.027 4.02995 16.049 4.00195 16.049C3.97395 16.049 3.95195 16.027 3.95195 15.999C3.95195 15.972 3.97395 15.949 4.00195 15.949" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M8.00099 15.951C8.02899 15.951 8.05099 15.973 8.05099 16.001C8.05099 16.029 8.02899 16.051 8.00099 16.051C7.97299 16.051 7.95099 16.029 7.95099 16.001C7.94999 15.973 7.97299 15.951 8.00099 15.951" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M12.002 15.949C12.03 15.949 12.052 15.971 12.052 15.999C12.052 16.027 12.03 16.049 12.002 16.049C11.974 16.049 11.952 16.027 11.952 15.999C11.952 15.972 11.974 15.949 12.002 15.949" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.PUBLICATION_INSTABILITY" [class]="size"  viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M9.55 14.3378C9.467 14.3378 9.4 14.4048 9.401 14.4878C9.401 14.5708 9.468 14.6378 9.551 14.6378C9.634 14.6378 9.701 14.5708 9.701 14.4878C9.701 14.4048 9.633 14.3378 9.55 14.3378" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M18.11 3.52977L15.11 1.00977" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1 3.52L4 1" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M9.55099 11.155V6.70898" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M9.55098 2.70801V2.70801C13.97 2.70801 17.552 6.29001 17.552 10.71V10.71C17.552 15.129 13.97 18.711 9.55098 18.711V18.711C5.13198 18.711 1.54898 15.129 1.54898 10.71V10.71C1.54898 6.29101 5.13098 2.70801 9.55098 2.70801Z" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.FILES" [class]="size" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M9 17H17C18.1046 17 19 16.1046 19 15V5.94C19 4.83543 18.1046 3.94 17 3.94H10.5291C10.1981 3.94 9.88856 3.7762 9.70236 3.50253L8.29736 1.43747C8.11115 1.16379 7.80157 0.999986 7.47054 1H3C1.89543 1 1 1.89543 1 3V9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M5.5 13.5L3 16L1.5 14.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.SUPPLIER" [class]="size" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M15.0067 15.5077H7.1734" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M18.0039 15.5077H20.0088C20.5613 15.5077 21.0092 15.0598 21.0092 14.5073V8.39012C21.0092 8.13571 20.9606 7.88364 20.8661 7.64744L19.5108 4.26009C19.2069 3.50054 18.4713 3.0025 17.6532 3.0025H14.0063" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M21.0092 10.0054H17.5077C17.2315 10.0054 17.0075 9.78147 17.0075 9.50521V6.00375H20.2088" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M17.5643 14.4507C17.9935 14.8798 18.122 15.5253 17.8897 16.086C17.6575 16.6468 17.1103 17.0124 16.5034 17.0125C15.8964 17.0125 15.3492 16.6469 15.1169 16.0862C14.8846 15.5254 15.013 14.88 15.4421 14.4508L15.4423 14.4507C15.7237 14.1693 16.1053 14.0112 16.5033 14.0112C16.9013 14.0112 17.2829 14.1693 17.5643 14.4507V14.4507" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M6.55976 14.4507C6.98896 14.8798 7.11738 15.5253 6.88514 16.086C6.6529 16.6468 6.10574 17.0124 5.49879 17.0125C4.89184 17.0125 4.34464 16.6469 4.11234 16.0862C3.88005 15.5254 4.0084 14.88 4.43755 14.4508L4.43786 14.4505C4.71924 14.1691 5.1009 14.011 5.49884 14.0111C5.89678 14.0111 6.2784 14.1692 6.55974 14.4507V14.4507" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M4.00208 15.5077H2.00125C1.44873 15.5077 1.00083 15.0598 1.00083 14.5073V2.00208C1.00083 1.44956 1.44873 1.00166 2.00125 1.00166H13.0058C13.5583 1.00166 14.0062 1.44956 14.0062 2.00208V11.0058" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.0062 11.0058H1.00083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.CUSTOMER" [class]="size" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M4 20H2C1.448 20 1 19.552 1 19V13C1 12.448 1.448 12 2 12H4C4.552 12 5 12.448 5 13V19C5 19.552 4.552 20 4 20Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M11 16H13.333C13.766 16 14.187 15.86 14.533 15.6L16.949 13.788C17.552 13.336 18.396 13.396 18.929 13.929V13.929C19.521 14.521 19.521 15.48 18.929 16.071L16.856 18.144C16.298 18.702 15.586 19.083 14.812 19.238L11.882 19.824C11.301 19.94 10.702 19.926 10.127 19.782L7.477 19.12C7.16 19.04 6.835 19 6.508 19H5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M11 16H12.485C13.322 16 14 15.322 14 14.485V14.182C14 13.487 13.527 12.881 12.853 12.713L10.561 12.14C10.188 12.047 9.806 12 9.422 12V12C8.495 12 7.588 12.274 6.817 12.789L5 14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M15.1688 4.15521C16.2771 5.26352 16.2771 7.06044 15.1688 8.16875C14.0605 9.27706 12.2636 9.27706 11.1553 8.16875C10.047 7.06044 10.047 5.26352 11.1553 4.15521C12.2636 3.0469 14.0605 3.0469 15.1688 4.15521" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M12.631 3.375C12.538 2.81 12.28 2.267 11.844 1.831C10.736 0.723 8.939 0.723 7.831 1.831C6.723 2.939 6.723 4.736 7.831 5.844C8.521 6.534 9.477 6.793 10.368 6.624" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.ITEM" [class]="size" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M18.852 14.9967V6.99875C18.852 6.28375 18.471 5.62375 17.852 5.26675L10.926 1.26775C10.307 0.91075 9.545 0.91075 8.926 1.26775L2 5.26675C1.381 5.62375 1 6.28475 1 6.99875V14.9958C1 15.7108 1.381 16.3707 2 16.7277L8.926 20.7278C9.545 21.0848 10.307 21.0848 10.926 20.7278L17.852 16.7288C18.471 16.3718 18.852 15.7107 18.852 14.9967Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M4.096 11.6277L7.096 13.3677" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1.268 5.99875L9.926 10.9977L18.584 5.99875" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M9.926 20.9978V10.9978" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M5.596 8.49778L14.526 3.34778" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.SERVER" [class]="size" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M15 1H5C2.791 1 1 2.791 1 5V5C1 7.209 2.791 9 5 9H15C17.209 9 19 7.209 19 5V5C19 2.791 17.209 1 15 1Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M9 5H15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M15 9H5C2.791 9 1 10.791 1 13V13C1 15.209 2.791 17 5 17H15C17.209 17 19 15.209 19 13V13C19 10.791 17.209 9 15 9Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M5.03536 4.96464C5.05488 4.98417 5.05488 5.01583 5.03536 5.03536C5.01583 5.05488 4.98417 5.05488 4.96464 5.03536C4.94512 5.01583 4.94512 4.98417 4.96464 4.96464C4.98417 4.94512 5.01583 4.94512 5.03536 4.96464" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M9 13H15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M5.03536 12.9646C5.05488 12.9841 5.05488 13.0158 5.03536 13.0353C5.01583 13.0549 4.98417 13.0549 4.96464 13.0353C4.94512 13.0158 4.94512 12.9841 4.96464 12.9646C4.98417 12.9451 5.01583 12.9451 5.03536 12.9646" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.ADMINISTRATOR" [class]="size" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M9.00332 5.00142C10.2308 5.00142 11.2262 5.99683 11.2262 7.22434C11.2262 8.45186 10.2308 9.44727 9.00332 9.44727C7.77581 9.44727 6.7804 8.45286 6.7804 7.22434C6.7804 5.99583 7.77581 5.00142 9.00332 5.00142" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M17.0067 9.24518C17.0067 13.615 13.8484 17.7107 9.52355 18.9352C9.18541 19.0313 8.82126 19.0313 8.48312 18.9352C4.15832 17.7117 1 13.615 1 9.24518V5.21551C1 4.40317 1.4912 3.67086 2.24352 3.36373L7.10954 1.37291C8.32405 0.875698 9.68462 0.875698 10.8981 1.37291L15.7641 3.36373C16.5155 3.67086 17.0067 4.40317 17.0067 5.21551V9.24518Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M13.005 13.0048C12.889 12.7116 12.7079 12.4495 12.4768 12.2354V12.2354C12.0856 11.8733 11.5754 11.6712 11.0422 11.6712C10.1528 11.6712 7.85487 11.6712 6.9655 11.6712C6.43227 11.6712 5.92106 11.8733 5.5299 12.2354V12.2354C5.2988 12.4495 5.11873 12.7116 5.00168 13.0048" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.MONEY" [class]="size" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M2 9V5H10V9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M10 5H11V1H3V5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M13.8284 10.1716C15.3905 11.7337 15.3905 14.2663 13.8284 15.8284C12.2663 17.3905 9.73364 17.3905 8.17155 15.8284C6.60945 14.2663 6.60945 11.7337 8.17155 10.1716C9.73365 8.60947 12.2663 8.60947 13.8284 10.1716" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M2 13V17H11" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M11 9H1V13H7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.TIMER" [class]="size" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M18 5.5L16 3.5L17 4.5L14.657 6.843" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M7 1.5H11" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M9 8.5V12.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M9.5 12.498C9.5 12.222 9.276 11.999 9 12C8.724 12.001 8.5 12.225 8.5 12.501C8.5 12.777 8.723 13 8.999 13C9.275 13 9.499 12.776 9.5 12.499" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.657 6.843C17.781 9.967 17.781 15.033 14.657 18.157C11.533 21.281 6.467 21.281 3.343 18.157C0.219 15.033 0.219 9.967 3.343 6.843C6.467 3.719 11.533 3.719 14.657 6.843" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.PARAMETERS" [class]="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M16 3H19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1 3H12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M8 10H19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1 10H4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M16 17H19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M1 17H12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M15.4142 1.58579C16.1952 2.36684 16.1952 3.63317 15.4142 4.41422C14.6331 5.19527 13.3668 5.19527 12.5858 4.41422C11.8047 3.63317 11.8047 2.36684 12.5858 1.58579C13.3668 0.804738 14.6331 0.804738 15.4142 1.58579" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M7.41422 8.58579C8.19527 9.36684 8.19527 10.6332 7.41422 11.4142C6.63317 12.1953 5.36684 12.1953 4.58579 11.4142C3.80474 10.6332 3.80474 9.36684 4.58579 8.58579C5.36684 7.80474 6.63317 7.80474 7.41422 8.58579" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M15.4142 15.5858C16.1952 16.3668 16.1952 17.6332 15.4142 18.4142C14.6331 19.1953 13.3668 19.1953 12.5858 18.4142C11.8047 17.6332 11.8047 16.3668 12.5858 15.5858C13.3668 14.8047 14.6331 14.8047 15.4142 15.5858" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.EVENTS" [class]="size" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M21 3C21 1.89543 20.1046 1 19 1H17C16.7391 1.01101 16.5151 1.18921 16.4457 1.441C16.2419 2.28442 15.487 2.87857 14.6194 2.87855C13.7517 2.87854 12.9968 2.28435 12.793 1.44093C12.7237 1.18918 12.4997 1.011 12.2388 1H3C1.89543 1 1 1.89543 1 3V14C1 15.1046 1.89543 16 3 16H12.2388C12.4997 15.989 12.7237 15.8108 12.793 15.5591C12.9968 14.7157 13.7517 14.1215 14.6194 14.1215C15.487 14.1214 16.2419 14.7156 16.4457 15.559C16.5151 15.8108 16.7391 15.989 17 16H19C20.1046 16 21 15.1046 21 14V3Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.5 11.75V11.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.5 8.74999V8.24999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M14.5 5.74999V5.24999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.CHECK" [class]="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle [class]="color" cx="10.0037" cy="10.0037" r="9.00375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M6.44626 10.3426L8.61416 12.5105L8.60016 12.4965L13.4912 7.60547" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.EYE" [class]="size" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M12.122 5.88024C13.293 7.05124 13.293 8.95224 12.122 10.1252C10.951 11.2962 9.05 11.2962 7.877 10.1252C6.706 8.95424 6.706 7.05324 7.877 5.88024C9.05 4.70724 10.95 4.70724 12.122 5.88024" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M1 8C1 7.341 1.152 6.689 1.446 6.088V6.088C2.961 2.991 6.309 1 10 1C13.691 1 17.039 2.991 18.554 6.088V6.088C18.848 6.689 19 7.341 19 8C19 8.659 18.848 9.311 18.554 9.912V9.912C17.039 13.009 13.691 15 10 15C6.309 15 2.961 13.009 1.446 9.912V9.912C1.152 9.311 1 8.659 1 8Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.WARNING" [class]="size" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M10.9998 11.1592V7.41919" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M10.9988 14.1643C10.8608 14.1643 10.7488 14.2763 10.7498 14.4143C10.7498 14.5523 10.8618 14.6643 10.9998 14.6643C11.1378 14.6643 11.2498 14.5523 11.2498 14.4143C11.2498 14.2763 11.1378 14.1643 10.9988 14.1643" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M13.0288 2.17825L20.6878 15.5822C21.5778 17.1402 20.4528 19.0793 18.6588 19.0793H3.34076C1.54576 19.0793 0.420763 17.1402 1.31176 15.5822L8.97076 2.17825C9.86776 0.60725 12.1318 0.60725 13.0288 2.17825Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchCase="iconAvaible.BUDGET" [class]="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M11.431 18.952L17.431 17.627C18.348 17.425 19 16.613 19 15.674V4.32597C19 3.38797 18.348 2.57497 17.431 2.37297L11.431 1.04797C10.183 0.771966 9 1.72197 9 3.00097V16.999C9 18.278 10.183 19.228 11.431 18.952Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M13.716 11.976L14.716 11.755C15.174 11.654 15.5 11.248 15.5 10.779V9.22098C15.5 8.75198 15.174 8.34598 14.716 8.24498L13.716 8.02398C13.091 7.88598 12.5 8.36098 12.5 8.99998V11C12.5 11.639 13.091 12.114 13.716 11.976V11.976Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M4 18V19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M17 17.72V19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path [class]="color" d="M9 3.00098H3C1.895 3.00098 1 3.89598 1 5.00098V16C1 17.105 1.895 18 3 18H9.272" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg *ngSwitchDefault [class]="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path [class]="color" d="M6.99801 9.85608V14.1429" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
      <path [class]="color" d="M12.1131 9.85706H10.9226C10.3966 9.85706 9.97021 10.2835 9.97021 10.8095V13.1904C9.97021 13.7164 10.3966 14.1428 10.9226 14.1428H12.1131" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M16.394 14.2509H15.6437C15.0912 14.2509 14.6433 13.803 14.6433 13.2505V10.7494C14.6433 10.1969 15.0912 9.74902 15.6437 9.74902H16.394C16.9466 9.74902 17.3945 10.1969 17.3945 10.7494V13.2505C17.3945 13.803 16.9466 14.2509 16.394 14.2509Z" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
      <path [class]="color" fill-rule="evenodd" clip-rule="evenodd" d="M2.99634 16.0016V7.9983C2.99634 5.23572 5.23585 2.99622 7.99842 2.99622H16.0018C18.7643 2.99622 21.0038 5.23572 21.0038 7.9983V16.0016C21.0038 18.7642 18.7643 21.0037 16.0018 21.0037H7.99842C5.23585 21.0037 2.99634 18.7642 2.99634 16.0016Z" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
    </svg>
  </div>
</app-tooltip>
