import { HttpRequest, HttpResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { ToastService } from 'src/app/data/services/components/toast/toast.service'
import { LogService } from 'src/app/data/services/erp/log/log.service'
import { ToastSeverity } from 'src/app/domain/enums/components/toast.enum'
import { Toast } from 'src/app/domain/interfaces/components/toast.interface'
import { LogAction, LogFunction, LogModule, LogScreen } from '../../../enums/erp/log.enum'
import { LogAuxiliaryData } from '../../../models/erp/log/log-auxiliary-data.model'
import { OrderTable } from '../../../models/erp/order/order-table.model'

export class LogOrderManagement {

  private logService: LogService = inject(LogService)
  private toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    try {
      switch (auxiliaryData.function) {
        case LogFunction.SAVE_ORDER:
          this.generateLogSaveOrder(request, response)
        break
        case LogFunction.DELETE_ORDERS: 
          this.generateLogDeleteOrders(request, response, auxiliaryData.data)
        break
        case LogFunction.BOOKKEPING_ORDERS: 
          this.generateLogBookkepingOrders(request, response, auxiliaryData.data)
        break
        case LogFunction.BATCH_EDIT_ORDERS: 
          this.generateLogEditBatchOrders(request, response, auxiliaryData.data)
        break
      }
    } catch (error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error } as Toast)
    }
  }

  private setOrderModule(orderTypeId: number): LogModule {
    return [3, 4, 5].includes(orderTypeId) ? LogModule.PURCHASES : LogModule.SALES
  }

  private setOrderScreen(orderTypeId: number): LogScreen {
    let logScreen: LogScreen | any

    switch (orderTypeId) {
      case 1: 
        logScreen = LogScreen.PRODUCT_SALES_ORDER
      break
      case 2: 
        logScreen = LogScreen.SERVICE_SALES_ORDER
      break
      case 3: 
        logScreen = LogScreen.PRODUCT_PURCHASES_ORDER
      break
      case 4:
      case 5:
        logScreen = LogScreen.SERVICE_PURCHASES_ORDER
      break
    }

    return logScreen
  }

  private generateLogSaveOrder(request: HttpRequest<any>, response: HttpResponse<any>): void {
    if ((response.status !== 200) && (response.status !== 201)) return
    
    this.logService.create(
      this.setOrderModule(request.body.pedido.pedidoTipo.id as number),
      this.setOrderScreen(request.body.pedido.pedidoTipo.id as number),
      request.urlWithParams,
      request.body.pedido.id ? LogAction.EDIT : LogAction.CREATE,
      response.body.id,
      response.body.codigo,
      `O pedido (${response.body.data.codigo}) foi ${request.body.pedido.id ? 'editado' : 'criado'}`,
      request.body
    )
  }

  private generateLogDeleteOrders(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if (response.status !== 200) return

    auxiliaryData.selectedOrders.forEach((order: OrderTable) => {
      this.logService.create(
        this.setOrderModule(order.pedidoTipoId as number),
        this.setOrderScreen(order.pedidoTipoId as number),
        request.urlWithParams,
        LogAction.DELETE,
        order.id,
        order.codigo,
        `O pedido (${order.codigo}) foi excluído`,
        request.body
      )
    })
  }

  private generateLogBookkepingOrders(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if (response.status !== 200) return

    auxiliaryData.selectedOrders.forEach((order: OrderTable) => this.logService.create(
      this.setOrderModule(order.pedidoTipoId as number),
      this.setOrderScreen(order.pedidoTipoId as number),
      request.urlWithParams,
      LogAction.CREATE,
      order.id,
      order.codigo,
      `O pedido (${order.codigo}) foi escriturado`,
      request.body
    ))
  }

  private generateLogEditBatchOrders(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if (response.status !== 200) return

    if (!response.body[0].error) auxiliaryData.selectedOrders.forEach((order: OrderTable) => this.logService.create(
      this.setOrderModule(order.pedidoTipoId as number),
      this.setOrderScreen(order.pedidoTipoId as number),
      request.urlWithParams,
      LogAction.EDIT,
      order.id,
      order.codigo,
      `O pedido (${order.codigo}) foi editado em lote`,
      request.body
    ))
  }
}