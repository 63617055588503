import { HttpRequest, HttpResponse } from "@angular/common/http"
import { inject } from "@angular/core"
import { ToastService } from "src/app/data/services/components/toast/toast.service"
import { LogService } from "src/app/data/services/erp/log/log.service"
import { ToastSeverity } from "src/app/domain/enums/components/toast.enum"
import { LogAction, LogFunction, LogModule, LogScreen } from "src/app/domain/enums/erp/log.enum"
import { Toast } from "src/app/domain/interfaces/components/toast.interface"
import { LogAuxiliaryData } from "src/app/domain/models/erp/log/log-auxiliary-data.model"
import { formatDateForDatePipeString } from "../../functions/format-date"

export class LogPeriodParameterManagement {

  private logService: LogService = inject(LogService)
  toastService: ToastService = inject(ToastService)

  public generateLog(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: LogAuxiliaryData): void {
    if(response.status !== 200) return

    try {
      switch(auxiliaryData.function) {
        case LogFunction.CHANGE_STOCK_PERIOD_STATUS:
          this.generateLogEditStockPeriodStatus(request, response, auxiliaryData.data.information)
        break
        case LogFunction.RECALCULATE_STOCK_PERIOD_COST:
          this.generateLogRecalculateStockPeriodCost(request, response, auxiliaryData.data.information)
        break
        case LogFunction.CHANGE_TAX_PERIOD_STATUS:
          this.generateLogEditTaxPeriodStatus(request, response, auxiliaryData.data.information)
        break
        case LogFunction.CHANGE_DATE_TAX_PERIOD:
          this.generateLogEditDateTaxPeriod(request, response, auxiliaryData.data.information)
        break
        case LogFunction.RECALCULATE_ACCOUNTING_PERIOD:
          this.generateLogRecalculateAccountingPeriod(request, response, auxiliaryData.data.information)
        break
        case LogFunction.CHANGE_ACCOUNTING_PERIOD_STATUS:
          this.generateLogEditAccountingPeriodStatus(request, response, auxiliaryData.data.information)
          break
        case LogFunction.RECALCULATE_FINANCIAL_PERIOD:
            this.generateLogRecalculateFinancialPeriod(request, response, auxiliaryData.data.information)
          break
        case LogFunction.CHANGE_FINANCIAL_PERIOD_STATUS:
          this.generateLogEditfinancialPeriodStatus(request, response, auxiliaryData.data.information)
        break
        case LogFunction.CHANGE_ACCOUNTS_DATES:
          this.generateLogChangeAccountDate(request, response, auxiliaryData.data.information)
        break
      }
    } catch(error) {
      this.toastService.sendToast({ severity: ToastSeverity.ERROR, message: error} as Toast)
    }
  }

  private generateLogEditTaxPeriodStatus(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if(response.body.error) return

    this.logService.create(
      LogModule.PARAMETER,
      LogScreen.PERIOD_PARAMETER_CENTER,
      request.urlWithParams,
      LogAction.EDIT,
      auxiliaryData.id,
      null,
      `O período fiscal de ${auxiliaryData.month} ${auxiliaryData.year} foi ${auxiliaryData.status}`,
      request.body
    )
  }

  private generateLogEditDateTaxPeriod(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if(response.body.error) return

    this.logService.create(
      LogModule.PARAMETER,
      LogScreen.PERIOD_PARAMETER_CENTER,
      request.urlWithParams,
      LogAction.EDIT,
      auxiliaryData.id,
      null,
      `A data de fechamento do período fiscal de ${auxiliaryData.month} ${auxiliaryData.year} foi alterada para ${auxiliaryData.closingDate}`,
      request.body
    )
  }

  private generateLogEditAccountingPeriodStatus(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if(response.body.error) return

    this.logService.create(
      LogModule.PARAMETER,
      LogScreen.PERIOD_PARAMETER_CENTER,
      request.urlWithParams,
      LogAction.EDIT,
      auxiliaryData.id,
      null,
      `O status do período contábil de ${auxiliaryData.month} ${auxiliaryData.year} foi atualizado para: ${auxiliaryData.status}`,
      request.body
    )
  }

  private generateLogRecalculateAccountingPeriod(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if(response.body.error) return

    this.logService.create(
      LogModule.PARAMETER,
      LogScreen.PERIOD_PARAMETER_CENTER,
      request.urlWithParams,
      LogAction.EDIT,
      request.body.ecd_id,
      null,
      `O período contábil de ${auxiliaryData.month} ${auxiliaryData.year} foi recalculado`,
      request.body
    )
  }

  private generateLogRecalculateStockPeriodCost(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if(response.body.error) return

    this.logService.create(
      LogModule.PARAMETER,
      LogScreen.PERIOD_PARAMETER_CENTER,
      request.urlWithParams,
      LogAction.EDIT,
      auxiliaryData.id,
      null,
      `O custo do período de estoque de ${auxiliaryData.month} ${auxiliaryData.year} foi recalculado`,
      request.body
    )
  }

  private generateLogEditStockPeriodStatus(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if(response.body.error) return

    this.logService.create(
      LogModule.PARAMETER,
      LogScreen.PERIOD_PARAMETER_CENTER,
      request.urlWithParams,
      LogAction.EDIT,
      auxiliaryData.id,
      null,
      `O período de estoque de ${auxiliaryData.month} ${auxiliaryData.year} foi ${auxiliaryData.status}`,
      request.body
    )
  }
  
  private generateLogRecalculateFinancialPeriod(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if(response.body.error) return

    this.logService.create(
      LogModule.PARAMETER,
      LogScreen.PERIOD_PARAMETER_CENTER,
      request.urlWithParams,
      LogAction.EDIT,
      auxiliaryData.id,
      null,
      `O saldo do período financeiro de ${auxiliaryData.month} ${auxiliaryData.year} foi recalculado`,
      request.body
    )
  }
  
  private generateLogEditfinancialPeriodStatus(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if(response.body.error) return

    this.logService.create(
      LogModule.PARAMETER,
      LogScreen.PERIOD_PARAMETER_CENTER,
      request.urlWithParams,
      LogAction.EDIT,
      auxiliaryData.id,
      null,
      `O status do período financeiro de ${auxiliaryData.month} ${auxiliaryData.year} foi atualizado para: ${auxiliaryData.status}`,
      request.body
    )
  }
  
  private generateLogChangeAccountDate(request: HttpRequest<any>, response: HttpResponse<any>, auxiliaryData: any): void {
    if (response.body.error) return

    const data: Array<{ id: number, account: string }> = auxiliaryData.data

    data.forEach(account => {
      this.logService.create(
        LogModule.PARAMETER,
        LogScreen.PERIOD_PARAMETER_CENTER,
        request.urlWithParams,
        LogAction.EDIT,
        account.id,
        null,
        `A data final da conta (${account.account}) foi alterada para ${formatDateForDatePipeString(auxiliaryData.date)}`,
        request.body
      )
    })
  }
}