import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FileToBase64Pipe } from './pipes/image/file-to-base64.pipe'
import { MapInTemplatePipe } from './pipes/map/map-in-template.pipe'
import { PrettyJsonPipe } from './pipes/pretty-json/pretty-json.pipe'
import { CheckFieldPipe } from './pipes/check-field.pipe'
import { MatchInTemplatePipe } from './pipes/match/match-in-template.pipe'

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PrettyJsonPipe,
    CheckFieldPipe,
    FileToBase64Pipe,
    MapInTemplatePipe,
    MatchInTemplatePipe
  ],
  exports: [
    PrettyJsonPipe,
    CheckFieldPipe,
    FileToBase64Pipe,
    MapInTemplatePipe,
    MatchInTemplatePipe
  ]
})
export class DomainModule { }
