import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { AvatarModule } from 'primeng/avatar'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { CheckboxModule } from 'primeng/checkbox'
import { ChipModule } from 'primeng/chip'
import { ChipsModule } from 'primeng/chips'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DataViewModule } from 'primeng/dataview'
import { DialogModule } from 'primeng/dialog'
import { DropdownModule } from 'primeng/dropdown'
import { EditorModule } from 'primeng/editor'
import { FileUploadModule } from 'primeng/fileupload'
import { GalleriaModule } from 'primeng/galleria'
import { ImageModule } from 'primeng/image'
import { InputMaskModule } from 'primeng/inputmask'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputSwitchModule } from 'primeng/inputswitch'
import { InputTextModule } from 'primeng/inputtext'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { MultiSelectModule } from 'primeng/multiselect'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { PaginatorModule } from 'primeng/paginator'
import { PanelModule } from 'primeng/panel'
import { PasswordModule } from 'primeng/password'
import { PickListModule } from 'primeng/picklist'
import { ProgressBarModule } from 'primeng/progressbar'
import { RadioButtonModule } from 'primeng/radiobutton'
import { RippleModule } from 'primeng/ripple'
import { SidebarModule } from 'primeng/sidebar'
import { SkeletonModule } from 'primeng/skeleton'
import { TableModule } from 'primeng/table'
import { TabMenuModule } from 'primeng/tabmenu'
import { TabViewModule } from 'primeng/tabview'
import { TieredMenuModule } from 'primeng/tieredmenu'
import { ToastModule } from 'primeng/toast'
import { ToolbarModule } from 'primeng/toolbar'
import { TooltipModule } from 'primeng/tooltip'
import { TreeModule } from 'primeng/tree'
import { TreeTableModule } from 'primeng/treetable'


@NgModule({
  imports: [
    CommonModule,
    RippleModule,
    ButtonModule,
    InputTextModule,
    ToastModule,
    DropdownModule,
    AvatarModule,
    TieredMenuModule,
    CalendarModule,
    TooltipModule,
    TableModule,
    CheckboxModule,
    OverlayPanelModule,
    ToolbarModule,
    ConfirmDialogModule,
    DialogModule,
    ChipModule,
    EditorModule,
    FileUploadModule,
    InputTextareaModule,
    PasswordModule,
    InputMaskModule,
    InputNumberModule,
    RadioButtonModule,
    InputSwitchModule,
    MultiSelectModule,
    TabMenuModule,
    PanelModule,
    DataViewModule,
    TabViewModule,
    PaginatorModule,
    SidebarModule,
    ProgressBarModule,
    TreeModule,
    PickListModule,
    TreeTableModule,
    ImageModule,
    GalleriaModule,
    ChipsModule,
    SkeletonModule
  ],
  exports: [
    RippleModule,
    ButtonModule,
    InputTextModule,
    ToastModule,
    DropdownModule,
    AvatarModule,
    TieredMenuModule,
    CalendarModule,
    TooltipModule,
    TableModule,
    CheckboxModule,
    OverlayPanelModule,
    ToolbarModule,
    ConfirmDialogModule,
    DialogModule,
    ChipModule,
    EditorModule,
    FileUploadModule,
    InputTextareaModule,
    PasswordModule,
    InputMaskModule,
    InputNumberModule,
    RadioButtonModule,
    InputSwitchModule,
    MultiSelectModule,
    TabMenuModule,
    PanelModule,
    DataViewModule,
    TabViewModule,
    PaginatorModule,
    SidebarModule,
    ProgressBarModule,
    TreeModule,
    PickListModule,
    TreeTableModule,
    ImageModule,
    GalleriaModule,
    ChipsModule,
    SkeletonModule
  ]
})
export class PrimengModule { }
